.Wrapper {
	min-width: 100%;
	background-color: #FFFFFF;
	padding: 110px 50px;
	border: 2px dashed #ec9a05;
}
.Container {
	height: 100%;
	max-width: 1220px;
	border: 4px dashed #7eaeff;
	margin: 0 auto;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.Bread > p{
	color:#54545E;
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 400;
	line-height: 25px;
	margin-bottom: 35px;
}
.Bread > p > span{
	color:#536C92;
}
.Themes{
	max-width: 860px;
	margin-bottom: 25px;
}
.Themes > h4, .Years > h4{
	color: rgba(84, 84, 94, 1);
	font-family: MontserratMedium;
	font-size: 17px;
	font-weight: 500;
	line-height: 25px;
	margin-bottom: 10px;
}
.Themes > div{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;align-items: flex-start;
	flex-wrap: wrap;
	height: 100%;
}
.Themes > div > span, .Years > div > span{
	color: rgba(84, 84, 94, 1);
	font-family: Montserrat;
	font-size: 15px;
	font-weight: 400;
	line-height: 25px;
	white-space: nowrap;
	margin-right: 30px;
}
.Themes > div > span:hover, .Years > div > span:hover{
	font-family: MontserratMedium;
	color:#536C92;
	cursor: pointer;
	text-decoration: underline;
}
.Years {
	margin-bottom: 40px;
}