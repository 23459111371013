.Wrapper {
	min-width: 100%;
	background-color: #FFFFFF;
	padding: 110px 50px;
	/*border: 2px dashed #ec9a05;*/
}
.Container {
	height: 100%;
	max-width: 1220px;
	/*border: 4px dashed #7eaeff;*/
	margin: 0 auto;
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
}
.Bread > p{
	color:#54545E;
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 400;
	line-height: 25px;
	margin-bottom: 35px;
}
.Bread > p > span{
	color:#536C92;
}
.Themes{
	max-width: 860px;
	margin-bottom: 25px;
}
.Themes > h4, .Years > h4{
	color: rgba(84, 84, 94, 1);
	font-family: MontserratMedium;
	font-size: 17px;
	font-weight: 500;
	line-height: 25px;
	margin-bottom: 10px;
}
.Themes > div{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;align-items: flex-start;
	flex-wrap: wrap;
	height: 100%;
}
.Themes > div > span, .Years > div > span{
	color: rgba(84, 84, 94, 1);
	font-family: Montserrat;
	font-size: 15px;
	font-weight: 400;
	line-height: 25px;
	white-space: nowrap;
	margin-right: 30px;
}
.Themes > div > span:hover, .Years > div > span:hover{
	font-family: MontserratMedium;
	color:#536C92;
	cursor: pointer;
	text-decoration: underline;
}
.Years {
	margin-bottom: 40px;
}
.Image{
	height: 300px;
	width: 300px;
	object-fit: cover;
	margin:0 0 20px 0;
}
.Date{
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	color:#54545E;
}
.New{
	color:#54545E;
	max-width: 290px;
	height: 445px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.Wrapper2 {
	min-width: 100%;
	background-color: #FFFFFF;
	padding: 31px 0 0 0;
	/*border: 2px dashed #ec9a05;*/
	display: flex;
	flex-direction: column;
	align-items: center;
	position:relative;
}
.Author{
	font-family: MontserratMedium;
	font-size: 17px;
	font-weight: 500;
	line-height: 25px;
	margin-bottom: 13px;
	max-width: 283px;
}
.Container3 {
	height: 100%;
	width: 100%;
	max-width: 1220px;
	/*border: 4px dashed #7eaeff;*/
	margin: 0 auto;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.BigP{
	max-width: 595px !important;
}
.MainBlock{
	width: 100%;
}
.ContentWrapper{
	display: flex;
	flex-direction: row;align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	column-gap: 30px;
	row-gap: 60px;
	width: 100%;
	/*border:2px solid indianred;*/
}
.New > p:first-child{
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
}
@media only screen and (max-width: 1000px) {
	.Container{
		flex-direction: column;
	}
	.MainBlock{
		margin-top: 30px;
	}
}
@media only screen and (max-width: 850px) {
	.Wrapper{
		padding: calc(45px + 50 * 100vw / 800) calc(25px + 22 * 100vw / 800);
	}
}
@media only screen and (max-width: 800px) {
	.Container3{
		display: none;
	}
	.Container{
		margin-top: 50px;
	}
}