.Wrapper {
	height: 100%;
	width: 110px;
	/*min-width: 110px;*/
	/*max-width: 110px;*/
	background-color: rgba(46, 46, 52, 0.7);
	position: fixed;
	top: 0;
	right: 0;
	z-index: 9999;
	overflow: visible;
	/*backdrop-filter: blur(10px);*/
	transition: all 0.8s ease-in-out 0s;
}

.ActiveMenu {
	height: 100%;
	width: 510px;
	backdrop-filter: blur(10px);
	/*background-color: rgb(234, 10, 138);*/
	transition: all 1s ease-in-out 0s;
}

.HiddenMenu {
	transition: all 1s ease-in-out 0s;
	background: none;
}

.ContainerOfAll {
	height: 100%;
	width: 100%;
	display: flex;
	transition: all 1s ease-in-out 0s;
	align-items: flex-start;
	justify-content: flex-end;
}

.ContainerOfAllActive {
	height: 100%;
	width: 100%;
	display: flex;
	transition: all 1s ease-in-out 0s;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
}

.ContainerOfNav {
	position: fixed;
	right: 0;
	height: 100%;
	max-width: 44px;
	margin: 0px 34px 0px 35px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.Block {
	position: absolute;
	right: 110px;
	width: 100%;
	/*max-width: 400px;*/
	transition: all 3.5s;
	height: 400px;
	background-color: #ec9a05;
}


.Languages {
	transition: all 0.5s ease-in-out 0s;
	/*height: 120px;*/
	width: 40px;
	border-radius: 6px;
	border: 1px solid #2E2E34B2;
	margin-bottom: 67px;
	
}

.NoneLanguages {
	transition: all 0.5s ease-in-out 0s;
	display: none;
	/*visibility: hidden;*/
	width: 40px;
	border-radius: 6px;
	border: 1px solid #2E2E34B2;
	margin-bottom: 67px;
}

.Item {
	transition: all 0.5s ease-in-out 0s;
	cursor: pointer;
	width: 100%;
	padding: 8px 8px;
	color: #A1A1AB;
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
	/*transition: all .3s;*/
}

.Item:first-child {
	border-radius: 6px 6px 0 0;
}

.Item:last-child {
	border-radius: 0 0 6px 6px;
}

.Item + .Item {
	border-top: 1px solid #2E2E34B2;
}

.Item:hover, .Filtered, .FilteredGr, .FilteredEn {
	transition: all 0.5s ease-in-out 0s;
	width: 100%;
	padding: 8px 8px;
	font-family: MontserratMedium;
	font-size: 17px;
	font-weight: 500;
	line-height: 25px;
	color: #FFFFFF;
	box-shadow: inset 0px 0px 10px 4px rgba(0, 0, 0, 0.35);
}

.FilteredGr {
	border-radius: 6px 6px 0 0;
}

.FilteredEn {
	border-radius: 0 0 6px 6px;
}


.Menu, .BlackMenu {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Menu > p, .BlackMenu > p {
	font-family: MontserratMedium;
	font-size: 13px;
	font-weight: 500;
	line-height: 15px;
	letter-spacing: -0.02em;
	margin-bottom: 11px;
	color: #FFFFFF;
	margin-top: 38px;
}

.BlackMenu > p {
	color: #54545E;
}

.BlackMenu svg path {
	transition: all 0.5s ease-in-out 0s;
	stroke-width: 1px;
	stroke-linecap: butt;
	stroke-dasharray: 0;
	stroke: #54545E;;
	fill: #54545E;
}

.Icon2 {
	margin-top: 63px;
	transition: all 1.5s;
}

.Blur {
	width: 100vw;
	height: 100vh;
	background-color: rgba(84, 84, 94, 0.6);
	/*backdrop-filter: blur(2px);*/
	position: fixed;
	opacity: 0.5;
	right: 0;
	z-index: 2;
	transition: all 0.8s;
}

@media only screen and (max-width: 800px) {
	.Wrapper {
		display: none;
	}
}

