.Wrapper {
	min-width: 100%;
	background-color: #FFFFFF;
	padding: 39px 50px 110px 50px;
	/*border: 2px dashed #ec9a05;*/
	background-size: cover;
}
.Container {
	height: 100%;
	max-width: 1220px;
	/*border: 4px dashed #7eaeff;*/
	margin: 0 auto;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.Container3 {
	height: 100%;
	width: 100%;
	max-width: 1220px;
	/*border: 4px dashed #7eaeff;*/
	margin: 0 auto;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding-bottom: 110px;
}
.Column{
	width: 100%;
}
.Main {
	color: #54545E;
}
.Table1 > .caption{
	margin-top: 15px;
	margin-bottom: 30px;
}
.Main > h3, .Table1 > .caption{
	font-family: MontserratMedium;
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	letter-spacing: -0.02em;
	text-align: left;
}
.Table1, .Table2, .Table3{
	width: 100%;
	margin-bottom: 20px;
}
.Deal{
	font-family: MontserratSemiBold;
	font-size: 17px;
	font-weight: 500;
	line-height: 25px;
	padding: 25px 0;
	color:pink;min-width: 100%;
	color: rgba(84, 84, 94, 1);
	border-bottom:1px solid #A1A1AB;
}
.Inside, .Inside2, .Insidde2, .InsideM{
	color: #54545E;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 25px 0 25px 25px;
	border-bottom:1px solid #A1A1AB;
}
.Inside > div:first-child, .Inside2 > div:first-child, .Insidde2 > div:first-child, .InsideM > div:first-child{
	width: 25%;
	font-family: MontserratMedium;
	font-size: 17px;
	font-weight: 500;
	line-height: 25px;
	text-align: left;
	margin-right: 80px;
	color: #54545E;
}
.Inside > div:nth-child(2), .InsideM > div:nth-child(2) {
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
}
.Inside2 > div, .Insidde2 > div, .InsideM > div {
	width: 30%;
}
.Inside > div, .InsideM > div{
	white-space: pre-wrap;
	word-wrap: break-word;
	color:#54545e;
	width: 25%;
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
	padding-right: 10px;
}
.InsideM > .Date{
	/*width: 15%;*/
}
.InsideM > .Time{
	/*width: 10%;*/
}
.InsideM > .Result {
	width: 25%;
	margin-left: 20px;
}
.Ar > div{
	width: 25%;
	margin-left: 0px !important;
}
.Table1 > div td:first-child, .Table2 > tr + tr > td:first-child, .Table3 > tr + tr > td:first-child{
	width: 32%;
	font-family: 'MontserratMedium';
	max-width: 260px;
	padding: 25px 30px 25px 25px;
	color:	#54545E;
	border-bottom:1px solid #A1A1AB;
}
.Table2 > tr + tr > td, .Table3 > tr + tr > td{
	white-space: pre-wrap;
	word-wrap: break-word;
	max-width: 300px;
	padding: 25px 0;
	color:	#54545E;;
	border-bottom:1px solid #A1A1AB;
	padding-right: 30px;
}
.Table1 > tr td:nth-child(2){
	max-width: 630px;
	padding: 15px 0;
	color:#54545E;
	border-bottom:1px solid #A1A1AB;
}
.Table1 > div + div + div:nth-child(2n), .Table2 > div + div + div:nth-child(2n+1),  .Table3 > div + div + div:nth-child(2n+1){
	background-color: rgba(245, 245, 245, 1);
}
.Inside2 > .Wr{
	color: #54545E;
	width: 50%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}
.Inside > .Ar{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}
@media only screen and (max-width: 1000px) {
	.Container {
		height: 100%;
		max-width: 1220px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
	.Column{
		margin-top: 30px;
	}
}


@media only screen and (max-width: 850px) {
	.Wrapper{
		padding: calc(45px + 50 * 100vw / 800) calc(25px + 22 * 100vw / 800);
	}
	.Inside > div, .InsideM > div{
		width: 50%;
	}
	.InsideM{
		display: none;
	}
	
	.Inside > div:first-child, .Inside2 > div:first-child, .Insidde2 > div:first-child, .InsideM > div:first-child{
		padding-right: 0;
		width: 45%;
		margin-right: 20px;
		color: #54545E;
	}
}
@media only screen and (max-width: 800px) {
	.Container3{
		display: none;
	}
	.Container{
		margin-top: 80px;
	}
	.Inside > .Ar{
		flex-direction: column;
	}
	.Ar > div{
		width: 100%;
		margin-left: 0px !important;
	}
}
@media only screen and (max-width: 550px) {
	.Insidde2, .InsideM {
		display: none;
	}
	.Inside2 > .Wr{
		width: 50%;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
	}
}
@media only screen and (max-width: 500px) {
	
	.Inside > div:first-child, .Inside2 > div:first-child, .Insidde2 > div:first-child{
		width: 40%;
	}
	.Inside, .Inside2, .Insidde2{
		flex-wrap: wrap;
		padding: 25px 10px 25px 10px;
	}
}


