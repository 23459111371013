.Wrapper {
	min-width: 100%;
	background-color: #FFFFFF;
	padding: 110px 50px;
	/*border: 2px dashed #ec9a05;*/
}

.Container {
	height: 100%;
	max-width: 1220px;
	/*border: 4px dashed #7eaeff;*/
	margin: 0 auto;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.CaseWrapper{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-basis: 283px;
	height: 185px;
	max-width: 283px;
	background: #D9E7F4;
	padding: 25px 20px;
	width: 283px;
}
.CaseWrapper > p {
	font-family: MontserratSemiBold;
	font-size: 15px;
	font-weight: 600;
	color: #54545E;
	line-height: 25px;
	margin-bottom: 10px;
	text-transform: uppercase;
}
.InputCase{
	color: #A1A1AB;
	background: none;
	font-family: Montserrat;
	font-size: 18px;
	font-weight: 600;
	line-height: 25px;
	min-width: 100%;
	border: none;
	outline: none;
	
	border-bottom: 1px solid rgba(84, 84, 94, 0.3);
	margin-bottom: 5px;
}
.Error{
	font-family: Montserrat;
	font-size: 13px;
	font-weight: 400;
	line-height: 20px;
	color: red;
}

.WrapperBtn {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 15px;
}
.Description > p{
	max-width: 595px;
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
	letter-spacing: 0em;
	text-align: left;
	margin-left: 30px;
	color: #54545E;
}
.Description > p:first-child{
	margin-bottom: 30px;
}
@media only screen and (max-width: 850px) {
	.Wrapper {
		padding: calc(45px + 50 * 100vw / 800) calc(25px + 22 * 100vw / 800);
	}
	.Container {
		flex-direction: column;
	}
	.Description > p:first-child{
		margin-top: 20px;
		margin-bottom: 15px;
	}
	.Description > p{
		margin-left: 0;
	}
	
	.MainContent {
		margin-top: 35px;
	}
}
@media only screen and (max-width: 430px) {
	.Container > div:nth-child(2), .CaseWrapper{
		width: 100%;
	}
}