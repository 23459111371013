.Wrapper {
	min-width: 100%;
	background-color: #FFFFFF;
	padding: 110px 50px;
	/*border: 2px dashed #ec9a05;*/
	background-size: cover;
}
.Container {
	height: 100%;
	max-width: 1220px;
	margin: 0 auto;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.Content{
	width: 100%;
	min-height: 465px;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.WrapperCounter {
	min-height: 465px;
	height: 100%;
	max-height: 540px;
	
	display: flex;
	min-width: 239px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	/*height: 460px;*/
	max-width: 283px;
	background: #D9E7F4;
	padding: 30px 20px;
}
.WrapperCounter > p{
	font-family: Montserrat;
	font-size: 13px;
	font-weight: 400;
	line-height: 20px;
	color: rgba(84, 84, 94, 0.7)
}
.WrapperCounter > h2{
	font-family: MontserratSemiBold;
	font-size: 35px;
	font-weight: 600;
	line-height: 40px;
	margin:18px 0 24px 0;
}
.WrapperCounter > button{
	background: none;
	font-family: MontserratSemiBold;
	font-size: 20px;
	font-weight: 600;
	line-height: 25px;
	color: rgba(84, 84, 94, 1);
	
	position: relative;z-index: 1;
}
.WrapperCounter > button::after{
	position:absolute;
	content:"";
	z-index: 999;
	bottom:2px;
	left:0;
	width:100%;
	border-bottom:1px solid #54545E;
}

.Column2{
	margin-left: 30px;
}
.TableRow > th, .TableRow2 > th{
	font-family: 'MontserratSemiBold';
	font-size: 20px;
	font-weight: 600;
	line-height: 25px;
	color:	#54545E;
	height: 30px;
	
	position: relative;
	z-index: 1;
	/*margin: 30px 0 15px 0;*/
}
.RusTableRow{
	padding: 10px 0;
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
	color:	#54545E;
	height: 30px;
}
.TableRow > th::after{
	position:absolute;
	content:"";
	z-index: 999;
	bottom:2px;
	left:0;
	width:30%;
	border-bottom:1px solid #54545E;
}
.TableRow2 > th::after{
	position:absolute;
	content:"";
	z-index: 999;
	bottom:2px;
	left:0;
	width:86%;
	border-bottom:1px solid #54545E;
}

.TableRow2 > th{
	padding-top: 40px;
}
.TableRow > td{
	padding: 10px 0;
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
	color:	#54545E;
	height: 30px;
}
.RusTableRow > td:nth-child(2){
	padding: 10px 0;
	font-family: Montserrat;
	
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
	color:	#54545E;
	height: 30px;
	/*color:deeppink;*/
}
.TableRow > td:nth-child(2){
	/*border:2px dashed hotpink;*/
	padding-left:86px;
}
.TableRow:last-child > td{
	font-family: MontserratMedium;
	color:	#54545E;
	font-size: 17px;
	font-weight: 500;
	line-height: 25px;
	color: rgba(84, 84, 94, 1);
}
.WrapperVtn{
	margin-top: 40px;
	display: flex;align-items: center;
}
@media only screen and (max-width: 1000px) {
	.Content{
		flex-direction: column;
	}
	.WrapperCounter{
		min-height: 100px;
		width: 100%;
		max-width: 100%;
	}
	.Column2{
		margin: 40px 0 0 0;
	}
	.TableRow2 > th {
		padding-top: 20px;
	}
}
@media only screen and (max-width: 330px) {
	.TableRow > td:nth-child(2){
		padding-left: 10px;
	}
	.WrapperCounter{
		min-width: 100%;
	}
}
