.Wrapper {
	min-width: 100%;
	background-color: #FFFFFF;
	padding: 110px 50px;
	border: 2px dashed #ec9a05;
}
.Container {
	height: 100%;
	max-width: 1220px;
	border: 4px dashed #7eaeff;
	margin: 0 auto;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.Description > p {
	font-family: Montserrat;
	font-size: 15px;
	font-weight: 400;
	line-height: 25px;
	letter-spacing: -0.02em;
	color: rgba(84, 84, 94, 1);
}
.Description > p {
	margin-bottom: 15px;
}
.Bread > p{
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 400;
	line-height: 25px;
	letter-spacing: -0.02em;
	text-align: left;
	margin-bottom: 27px;
}
.Bread + h3{
	font-family: MontserratSemiBold;
	font-size: 20px;
	font-weight: 600;
	line-height: 25px;
	color: rgba(84, 84, 94, 1);
	margin-bottom: 35px;
}
.Date{
	color: rgba(84, 84, 94, 1);
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	letter-spacing: -0.02em;
	margin-bottom: 30px;
}
.Cover{
	height: 100%;
	border:2px dashed purple;
	position: relative;min-height: 500px;
	margin-bottom: 40px;
}
.CoverContain{
	position: absolute;
	bottom: 0px;
	right: 60px;
	max-width: 593px;
	background-color: rgba(236, 236, 238, 1);
	padding: 40px 40px;
}
.CoverContain > p{
	margin-top: 15px;
	font-family: Montserrat;
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	color:#54545E;
}
.CoverContain > div{
	display: flex;align-items: center;justify-content: flex-start;
	margin-bottom: 10px;
	font-family: Montserrat;
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	color:#54545E;
}
.Vector{
	margin-right: 12px;
}