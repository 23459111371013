.Wrapper{
	background-image: url("./Main.png");
	min-width: 100%;
	height: 100vh;
	/*background-color: #FFFFFF;*/
	padding: 27px 50px 185px 50px;
	/*border: 2px dashed #ec9a05;*/
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: 100% 100%;
	/*opacity: 0.7;*/
	z-index: 1;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.Wrapper::before {
	content: '';
	min-width: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(46, 46, 52, 0.7);
}
.Wrapper > h2{
	position: relative;
	z-index: 999;
	font-family: MontserratSemiBold;
	font-size: 145px;
	font-weight: 600;
	line-height: 150px;
	color:#FFFFFF;
	text-align: center;
	margin-bottom: 10px;
}
.Wrapper > h3{
	position: relative;
	z-index: 999;
	font-family: MontserratSemiBold;
	font-size: 45px;
	font-weight: 600;
	line-height: 50px;
	color:#FFFFFF;
	text-align: center;
	margin-bottom: 25px;
}
.WrapperLogo {
	position: absolute;
	z-index: 99999;
	left:7%;
	top:31px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.WrapperLogo > p {
	font-family: Montserrat;
	font-size: 25px;
	font-weight: 600;
	line-height: 25px;
	letter-spacing: -0.02em;
	color: #FFFFFF;
}
.Logo{
	position: relative;
	z-index: 999;
	margin-right: 7px;
}
.Btn{
	font-family: MontserratMedium;
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	letter-spacing: -0.02em;
	background: none;
	position: relative;
	z-index: 1;
	margin-right: 10px;
	color: rgba(217, 231, 244, 1);
}
.WrapperBtn{
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.Btn::after{
	position:absolute;
	content:"";
	z-index: 999;
	bottom:2px;
	left:0;
	width:100%;
	border-bottom:1px solid rgba(217, 231, 244, 1);
}
.Union{
	position: relative;
	z-index: 999;
}


	
