.Wrapper {
	min-width: 100%;
	background-color: #FFFFFF;
	padding: 110px 50px;
	/*border: 2px dashed #ec9a05;*/
}

.Container {
	height: 100%;
	max-width: 1220px;
	/*border: 4px dashed #7eaeff;*/
	margin: 0 auto;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.Vector{
	
	transition: 0.5s;
}
.VectorDown{
	transform: rotate(180deg);
	transition: 0.5s;
}
.Content{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	/*border:2px dashed #ec9a05;*/
}
.Description > p{
	font-family: Montserrat;
	color: #54545E;
	font-size: 17px;
	line-height: 25px;
	max-width: 595px;
	margin-left: 30px;
}
.Description > p:first-child{
	margin-bottom: 25px;
}

.WrapperCounter {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	/*height: 460px;*/
	max-width: 283px;
	background: #D9E7F4;
	padding: 30px 20px;
}

.WrapperBtn {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 20px;
	flex-wrap: wrap;
}

.List {
	width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	-moz-appearance: textfield;
	background: #0a377e;
	margin: 0;
}

.CurrencyWrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 5px;
	cursor: pointer;
}
.CurrencyWrapper2{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	
}
.Bl{
	transition: all 0.5s;
}

.CurrencyWrapper > p , .CurrencyWrapper2 >  p{
	font-family: MontserratSemiBold;
	font-size: 17px;
	font-weight: 600;
	line-height: 25px;
	color: #A1A1AB;
	margin-right: 7px;
}

.Error{
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 400;
	line-height: 25px;
	color:red;
	display: inline-block;
	margin-bottom: 15px;
}

.Rules {
	width: 100%;
	position: relative;
	margin-bottom: 25px;
}

.Rules > p:first-child, .List > p {
	font-family: MontserratSemiBold;
	font-size: 15px;
	font-weight: 600;
	line-height: 25px;
	color: #54545E;
	margin-bottom: 11px;
}
.ChooseArbitrators > p{
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 400;
	line-height: 25px;
	color:rgba(84, 84, 94, 0.7);
	
}
.Rules > div {
	font-family: Montserrat;
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	color:#54545E;
}
.ChooseArbitrators > div{
	font-family: Montserrat;
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	color:#54545E;
}
.ChooseArbitrators > div:nth-child(2), .Rules > div:nth-child(2) {
	margin-bottom: 15px;
}

.Conditions {
	font-family: Montserrat;
	font-size: 8px;
	font-weight: 400;
	line-height: 15px;
	max-width: 210px;
	position: relative;
	left: 21px;
	margin-top: 6px;
}

.CountBlock {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-bottom: 0.5px solid #54545E;
	margin-bottom: 5px;
	transition: all 0.5s;
}

.InputCurrency {
	color: #A1A1AB;
	background: none;
	font-family: MontserratSemiBold;
	font-size: 17px;
	font-weight: 400;
	line-height: 20px;
	max-width: 90%;
	border: none;
	overflow: hidden;
	outline: none;
	padding: 0 0 10px 10px;
}
.CustomRadio{
	position: absolute;
	z-index: -1;
	opacity: 0;
}
.CustomRadio + label{
	display: inline-flex;
	align-items: center;
	user-select: none;
}
.CustomRadio + label::before {
	content: '';
	display: inline-block;
	width: 1em;
	height: 1em;
	flex-shrink: 0;
	flex-grow: 0;
	border: 1px solid rgba(84, 84, 94, 1);
	border-radius: 50%;
	margin-right: 0.5em;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
}
.CustomRadio:checked+label::before {
	/*content: '';*/
	/*background-color:  rgba(84, 84, 94, 1);*/
	background: url("./imgs/Check.svg");
	background-position: center;
}
@media only screen and (max-width: 850px) {
	.Wrapper{
		padding: calc(45px + 50 * 100vw / 800) calc(25px + 22 * 100vw / 800);
	}
	.Container{
		height: 100%;
		max-width: 1220px;
		display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;
	}
	.Content{
		margin-top: 35px;
	}
}
@media only screen and (max-width: 700px) {
	.Content{
		margin-top: 0;
	}
}
@media only screen and (max-width: 640px) {
	.Content{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
	}
	.Description{
		margin-top: 30px;
	}
	.Description > p{
		margin-left: 0;
	}
}
@media only screen and (max-width: 420px) {
	.WrapperCounter {
		width: 100%;
	}
}
