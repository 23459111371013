@font-face {
  font-family: 'Montserrat';
  src: url("./fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: 'MontserratMedium';
  src: url("./fonts/Montserrat-Medium.ttf");
}
@font-face {
  font-family: 'MontserratSemiBold';
  src: url("./fonts/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: 'MontserratBold';
  src: url("./fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: 'InterMedium';
  src: url("./fonts/Inter-Medium.ttf");
}

*,
*::after,
*:before{
  box-sizing: border-box;
}
a{
  text-decoration: none;
  color:inherit;
}
ul, li, h1, h2, h3, h4, p{
  list-style: none;
  padding: 0;
  margin: 0;
  color: #54545E;
  text-align: left
  
}

ul[class],
ol[class] {
  padding: 0;
}
button{
  text-align: left;
  border: none;
  outline:none;
  padding: 0 0;
}
body{
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  text-align: left;
}
img {
  max-width: 100%;
  display: block;
}
input,
button,
textarea,
select {
  font: inherit;
}
button{
  cursor: pointer;
}

::-webkit-input-placeholder {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.02em;
  text-align: left;
  color:#A1A1AB;
  /*padding: 0 10px 14px 8px;*/
  opacity: 0.7;
}
/*.element::-webkit-scrollbar {*/
/*  width: 0;*/
/*}*/

/*::-webkit-scrollbar {*/
/*  width: 0;*/
/*}*/

/*::-webkit-scrollbar {*/
/*  width: 0;*/
/*}*/