.Wrapper {
	position: relative;
	float: left;
	/*left:0;*/
	/*right: 50px;*/
	width: 100%;
	max-width: 400px;
	/*transition:  all 2.5s ;*/
	/*background-color: #ec9a05;*/
	height:100%;
	/*background-color: deeppink;*/
	/*background-color: rgba(46, 46, 52, 0.7);*/
}

.ActiveWrapper {
	width: 100%;
	transition:  all 2.5s;
	max-width: 400px;
	height: 100%;
	/*background-color: mediumpurple;*/
	
}

.Container {
	/*background-color: #ec9a05;*/
	/*background: greenyellow;*/
	display: flex;
	height: 100%;
	width: 100%;
	max-width: 400px;
	flex-direction: column;
	align-items: flex-start;
	transition: all 2.5s;
	/*border:2px solid #0a377e;*/
	padding: 0px 35px 30px 30px;
	overflow: hidden;
	overflow-y: scroll;
}
.Container::-webkit-scrollbar {
	width: 4px;
	max-height: 500px;
	background: none;
	opacity: 0.5;
	/*background: rgb(94, 92, 92);*/
	height: 503px;
	border-radius: 4px;
}
.Container::-webkit-scrollbar-thumb {
	height: 30px;
	background: rgba(131, 122, 122, 0.5);
	opacity: 0.2;
	border-radius: 4px;
	
}

.List {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	/*border: 2px dashed #ec9a05;*/
}

.ListItem {
	display: flex;
	flex-direction: row;justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 13px 0 13px 30px;
	border-bottom: 1px solid rgba(161, 161, 171, 1);
}
.ListItem:hover{
	background-color: rgba(217, 231, 244, 0.05);
}
.LinkReal{
	font-family: InterMedium;
	font-size: 17px;
	font-weight: 500;
	line-height: 25px;
	color:#FFFFFF;
}
.ListItemReal{
	width: 100%;
	padding: 13px 0 13px 15px;
	cursor: pointer;
	border-bottom: 1px solid #ccc;
	margin: 0;
	background: none;
}
.Link {
	width: 100%;
	color: rgba(236, 236, 238, 0.7);
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
	/*padding-left: 40px;*/
}
.Heading {
	font-family: MontserratMedium;
	font-size: 25px;
	font-weight: 500;
	line-height: 30px;
	color:#FFFFFF;
	/*margin-top: 68px;*/
	text-align: left;
	/*margin-left: 15px;*/
	margin:68px 0 10px 15px;
}
.Blur{
	width: 100vw;
	height: 100vh;
	background-color: #2e2e34;
	/*backdrop-filter: blur(2px);*/
	position: fixed;
	left:0;
	z-index: 1;
	transition: all 0.8s;
}




