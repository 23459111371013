.Wrapper{
	min-width: 100%;
	padding: 110px 50px;
	background-image: url("bg.png");
	background-size: 100% 100%;
}
.Container{
	height: 100%;
	max-width: 1220px;
	margin:0 auto;
	display: flex;flex-direction: row;justify-content: flex-start;align-items: flex-start;
}
.FormBlock {
	display: flex;flex-direction: column;justify-content: center;align-items: flex-start;
	/*height: 334px;*/
	/*width: 908px;*/
	background-color: #D9E7F4;
	padding: 39px 31px 39px 39px;
}
.Form{
	width: 100%;
}
.FormContainer{
	width: 100%;
	display: flex;justify-content: space-between;align-items: center;
	row-gap: 25px;
}
.Column1, .Column2, .Column3{
	width: 30%;
	max-width: 443px;
	display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;
	margin-bottom: 12px;
}
.Column1 > .Field, .Column2 > .Field, .Column3 > .Field, .CommentBlock > .Field {
	font-family: MontserratMedium;
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	letter-spacing: -0.02em;
	text-align: left;
	color: rgba(161, 161, 171, 1);
	margin: 15px 0;
}
.Column1 > input, .Column2 > input, .Column3 > input, .CommentBlock > input{
	width: 100%;
	background: none;
	border:none;
	outline: none;
	border-bottom: 0.5px solid #54545E;
	padding-bottom: 5px;
	padding-left: 8px;
	font-family: Montserrat;
	font-size: 12px;
	line-height: 20px;
	text-align: left;
	color: #8e8e94;
}
.Label{
	text-transform: uppercase;
	font-family: Montserrat;
	font-size: 15px;
	font-weight: 600;
	line-height: 25px;
	/*padding: 0 0 0 8px;*/
	color: rgba(84, 84, 94, 1);
}
.BtnWrapper{
	margin-top: 20px;
	display: flex;align-items: center;
}
.Btn{
	font-family: MontserratMedium;
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	letter-spacing: -0.02em;
	background: none;
	position: relative;
	z-index: 1;
	margin-right: 10px;
	color: #536C92;
}
.Btn::after{
	position:absolute;
	content:"";
	z-index: 999;
	bottom:2px;
	left:0;
	width:100%;
	border-bottom:1px solid #536C92;
}
.Success{
	height: 100%;
	margin: 0 auto;
	display: flex;flex-direction: column;align-items: center;
	justify-content: center;
}
.Success >p{
	margin-top: 25px;
}
@media only screen and (max-width: 800px) {
	.Wrapper{
		padding: calc(45px + 50 * 100vw / 800) calc(25px + 22 * 100vw / 800);
	}
}
@media only screen and (max-width: 700px) {
	.Container{
		height: 100%;
		max-width: 1220px;
		margin:0 auto;
		display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;
	}
}
@media only screen and (max-width: 550px) {
	.FormContainer{
		flex-direction: column;
	}
	
	.Column1, .Column2, .Column3{
		width: 100%;
		margin-bottom: 0;
	}
	.Label{
		padding: 0 0 0 0;
	}
}
@media only screen and (max-width: 320px) {
	.FormBlock{
		padding: 25px 21px 25px 21px;
	}
}

