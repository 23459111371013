.Detail{
	font-family: MontserratSemiBold;
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	letter-spacing: -0.02em;
	color: #54545E;
	background: none;
	position: relative;z-index: 1;

}
.Detail::after{
	position:absolute;
	content:"";
	z-index: 999;
	bottom:2px;
	left:0;
	width:100%;
	border-bottom:1px solid #54545E;
}