.Wrapper {
	min-width: 100%;
	min-height: 900px;
	/*background-color: #FFFFFF;*/
	padding: 27px 50px 185px 50px;
	/*border: 2px dashed #ec9a05;*/
	background-image: url("imgs/Main.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: 100% 100%;
	/*opacity: 0.7;*/
	z-index: 1;
	position: relative;
}

.Abra {
	position: relative;
	color: #ec9a05;
	font-size: 30px;
	cursor: pointer;
}

.Wrapper::before {
	content: '';
	min-width: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(46, 46, 52, 0.7);
	
}

.Container {
	height: 100%;
	/*height: 600px;*/
	/*padding-right: 30px;*/
	max-width: 1220px;
	/*border: 4px dashed #7eaeff;*/
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.WrapperLogo {
	position: relative;
	z-index: 99999;
	margin-bottom: 100px;
	height: 100%;
	width: 100%;
	max-width: 1220px;
	
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.WrapperLogo > div:first-child{
	display: flex;flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
}
.WrapperLogo > div:first-child > p {
	font-family: Montserrat;
	font-size: 25px;
	font-weight: 600;
	line-height: 25px;
	letter-spacing: -0.02em;
	color: #FFFFFF;
}
.Tel{
	margin-right: 7px;
}
.Logo {
	height: 45px;
	width: 45px;
	position: relative;
	z-index: 1;
	margin-right: 7px;
}

.MainBlock {
	display: flex;
	align-items: center;
}

.Description {
	position: relative;
	z-index: 99;
	max-width: 720px;
	margin-left: 30px;
}

.Description > h1 {
	font-family: MontserratSemiBold;
	font-size: 45px;
	font-weight: 600;
	line-height: 50px;
	color: #FFFFFF;
	margin-bottom: 20px;
}

.BtnWrapper {
	cursor: pointer;
	display: flex;
	align-items: center;
}

.Btn {
	font-family: MontserratMedium;
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	letter-spacing: -0.02em;
	background: none;
	position: relative;
	z-index: 1;
	margin-right: 10px;
	color: #D9E7F4;
}

.Btn::after {
	position: absolute;
	content: "";
	z-index: 999;
	bottom: 2px;
	left: 0;
	width: 100%;
	border-bottom: 1px solid #D9E7F4;
}
.Contact{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}
.Contact > span{
	color: rgba(255, 255, 255, 1);
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
	text-align: right;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
}
.Contact > p{
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
	text-align: left;
	color: rgba(255, 255, 255, 1);
}

@media only screen and (max-width: 850px) {
	.Wrapper {
		background-size: cover;
		background-position: center;
		padding: calc(45px + 50 * 100vw / 800) calc(25px + 22 * 100vw / 800);
	}
	
	.MainBlock {
		align-items: flex-start;
		flex-direction: column;
	}
	.Description {
		margin-top: 20px;
		margin-left: 0;
	}
	
}
@media only screen and (max-width: 800px) {
	.WrapperLogo {
		display: none;
	}
	.MainBlock{
		margin-top: 100px;
	}
}
@media only screen and (max-width: 700px) {
	.Description > h1 {
		font-size: 37px
	}
}

@media only screen and (max-width: 400px) {
	.MainBlock {
		align-items: center;
		flex-direction: column;
	}
}