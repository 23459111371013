.Wrapper {
	min-width: 100%;
	/*background-color: #FFFFFF;*/
	padding: 110px 50px;
	/*border: 2px dashed #ec9a05;*/
	background-image: url("imgs/bg.png");
	background-size: cover;
}
.Container {
	height: 100%;
	max-width: 1220px;
	/*border: 4px dashed #7eaeff;*/
	margin: 0 auto;
	display: flex;
	position: relative;
	justify-content: flex-start;
	align-items: flex-start;
}
.ContentWrapper{
	width: 100%;
	height: 100%;
	/*border:2px dashed mediumpurple;*/
	min-height: 420px;
}
.Image {
	max-height: 340px;
	max-width: 386px;
	object-fit: cover;
}
.Abra{
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	/*border:2px solid yellow;*/
	position: absolute;
	/*top:150px;*/
	bottom:0;
	right: 65px;
	max-width: 595px;
}
.WrapperDesc{
	padding: 40px 40px;
	background-color: #ECECEE;

}
.Description{
	font-family: Montserrat;
	margin-bottom: 16px;
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
}
@media only screen and (max-width: 950px) {
	.ContentWrapper{
		/*min-height: 620px;*/
	}
}
@media only screen and (max-width: 850px) {
	.Wrapper{
		padding: calc(45px + 50 * 100vw / 800) calc(25px + 22 * 100vw / 800);
	}
	.Container {
		flex-direction: column;
	}
	.ContentWrapper{
		margin-top: 35px;
		min-height: 520px;
	}
	.Abra{
		bottom:0;
		right: 0;
	}
}
@media only screen and (max-width: 720px) {
	.Abra{
		left: 20px;
	}
	.ContentWrapper{
		margin-top: 35px;
		min-height: 620px;
	}
}
@media only screen and (max-width: 520px) {
	.Image {
		width: 80%;
		height: 45%;
		object-fit: contain;
	}
}

@media only screen and (max-width: 360px) {
	.ContentWrapper{
		/*min-height: 700px;*/
	}
	.WrapperDesc{
		padding: 20px 20px;
	}
}
