.Subscribe {
	font-family: MontserratBold;
	background: none;
	font-size: 20px;
	font-weight: 700;
	line-height: 25px;
	min-width: 190px;
	padding-bottom: 5px;
	position: relative;
	z-index: 1;
	color:#536C92;
	width: 190px;
	margin-right: 40px;
}
.Subscribe::after{
	position:absolute;
	content:"";
	z-index: 999;
	bottom:0;
	left:0;
	width:71%;
	border-bottom:1px solid #536C92;
}
@media only screen and (max-width: 800px) {
	.Subscribe{
		min-width: 160px;
		max-width: 190px;
		margin-right: calc(10px + 20 * 100vw / 800);
	}
}
@media only screen and (max-width: 700px) {
	.Subscribe{
		margin-bottom: 35px;
	}
}