.Wrapper {
	min-width: 100%;
	background-color: #F7F7F7;
	padding: 110px 50px 110px 50px;
	/*border: 2px dashed #ec9a05;*/
}
.Wrapper2 {
	min-width: 100%;
	background-color: #F7F7F7;
	padding: 0px 50px 110px 50px;
	/*border: 2px dashed #ec9a05;*/
}
.Container {
	height: 100%;
	/*max-width: 1045px;*/
	max-width: 1220px;
	/*border: 4px dashed #7eaeff;*/
	margin: 0 auto;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	
}
.Container2{
	height: 100%;
	max-width: 1220px;
	/*border: 4px dashed #7eaeff;*/
	margin: 0 auto;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	flex-wrap: wrap;
}
.Column1 {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.Column2{
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-start;
	align-items: flex-start;
	margin:70px 0;
}
.Column1 > img, .Column2 > img{
	max-height: 330px;
	max-width: 280px;
}

.Description {
	font-family: Montserrat;
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	color: #54545E;
	margin-left: 31px;
}
.Column2 .Description{
	margin:0 31px 0 0 ;
}
.Description > div:first-child{

	width: 100%;
	border-top:1px solid #A1A1AB;
	border-bottom:1px solid #A1A1AB;
	padding:23px 0 22px 0;
	margin-bottom: 20px;
}
.Description > div > h4 {
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	font-family: Montserrat;
	color: #A1A1AB;
}
.Description > p{
	max-width: 595px;
}

.Description > div > h2 {
	max-width: 595px;
	font-family: MontserratSemiBold;
	font-size: 20px;
	font-weight: 600;
	line-height: 25px;
	padding-top: 5px;
}
.Date, .Time, .Location{
	display: flex;justify-content: flex-start;align-items: center;
	margin-bottom: 10px;
}
.Location{
	margin-bottom: 14px;
}
.Date > svg, .Time svg, .Location svg{
	margin-right: 11px;
}
.More{
	margin-top: 11px;
}
.Container > div:first-child{
	/*border:2px solid greenyellow;*/
}
.Container > div > div:nth-child(3){
	margin-bottom: 65px;
	/*border:2px dashed hotpink;*/
}
.Subscribe2{
	font-family: MontserratMedium;
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	letter-spacing: -0.02em;
	background: none;
	position: relative;
	z-index: 1;
	color: #536C92;
	margin-right: 10px;
}
.SubscribeWrapper{
	display: flex;justify-content: flex-start;align-items: center;
}
.Subscribe2::after{
	position:absolute;
	content:"";
	z-index: 999;
	bottom:2px;
	left:0;
	width:100%;
	border-bottom:1px solid #536C92;
}
