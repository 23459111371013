.Btn{
	font-family: MontserratMedium;
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	letter-spacing: -0.02em;
	background: none;
	position: relative;
	z-index: 1;
	margin-right: 10px;
	color: #536C92;
}
.Btn::after{
	position:absolute;
	content:"";
	z-index: 999;
	bottom:2px;
	left:0;
	width:100%;
	border-bottom:1px solid #536C92;
}