.Wrapper{
	display: none;
}
@media only screen and (max-width: 800px) {
	.Wrapper{
		display: block;
	}
	.Wrapper{
		position: fixed;
		width: 100vw;
		height: 100vh;
		top:90px;
		right: 0;
		z-index: 99999;
		transform: translate(100%);
		transition: transform  0.7s;
		background-color: rgba(46, 46, 52, 0.7);
		border-top: 1px solid grey;
		padding: calc(30px + 10 * 100vw / 800) calc(25px + 15 * 100vw / 800);
	}
	.ActiveWrapper{
		position: fixed;
		z-index: 99999;
		top:90px;
		right: 0;
		width: 100vw;
		height: 100vh;
		transform: translate(0);
		transition: transform 0.7s;
		background-color: rgba(46, 46, 52, 0.7);
		backdrop-filter: blur(10px);
		border-top: 1px solid grey;
		padding: calc(30px + 10 * 100vw / 800) calc(25px + 15 * 100vw / 800);
	}
	.Container{
		min-width: 100%;
		display: flex;
		height: 84%;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-start;
		overflow-y: scroll;
		padding-bottom: 40px;
		
	}
	.Container::-webkit-scrollbar {
		width: 4px;
		max-height: 500px;
		background: none;
		opacity: 0.5;
		/*background: rgb(94, 92, 92);*/
		height: 503px;
		border-radius: 4px;
	}
	.Container::-webkit-scrollbar-thumb {
		height: 30px;
		background: rgba(131, 122, 122, 0.5);
		opacity: 0.2;
		border-radius: 4px;
		
	}
	
	.List {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		max-width: 480px;
		width: 100%;
		/*border: 2px dashed #ec9a05;*/
	}
	
	.ListItem {
		display: flex;
		flex-direction: row;justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 13px 0 13px 30px;
		border-bottom: 1px solid rgba(161, 161, 171, 1);
	}
	.ListItem:hover{
		background-color: rgba(217, 231, 244, 0.05);
	}
	.LinkReal{
		font-family: InterMedium;
		font-size: 17px;
		font-weight: 500;
		line-height: 25px;
		color:#FFFFFF;
	}
	.ListItemReal{
		width: 100%;
		padding: 13px 0 13px 15px;
		cursor: pointer;
		border-bottom: 1px solid #ccc;
		margin: 0;
		background: none;
	}
	.Link {
		width: 100%;
		color: rgba(236, 236, 238, 0.7);
		font-family: Montserrat;
		font-size: 17px;
		font-weight: 400;
		line-height: 25px;
		/*padding-left: 40px;*/
	}
	.Languages {
		transition: all 0.5s ease-in-out 0s;
		/*height: 120px;*/
		height: 40px;
		border-radius: 6px;
		border: 1.5px solid #2E2E34B2;
		margin: 25px 10px 0 0 ;
		display: flex;
		flex-direction: row;
		/*background-color: yellow;*/
	}
	
	.Item {
		transition: all 0.5s ease-in-out 0s;
		cursor: pointer;
		width: 100%;
		padding: 8px 8px;
		color: #A1A1AB;
		font-family: Montserrat;
		font-size: 17px;
		font-weight: 400;
		line-height: 25px;
		/*transition: all .3s;*/
	}
	
	.Item:first-child {
		border-radius: 6px 0 0 6px;
	}
	
	.Item:last-child {
		border-radius: 0 0 6px 6px;
	}
	
	.Item + .Item {
		border-top: 1px solid #2E2E34B2;
	}
	
	.Item:hover, .Filtered, .FilteredGr, .FilteredEn {
		transition: all 0.5s ease-in-out 0s;
		width: 100%;
		padding: 8px 8px;
		font-family: MontserratMedium;
		font-size: 17px;
		font-weight: 500;
		line-height: 25px;
		color: #FFFFFF;
		box-shadow: inset 0px 0px 10px 4px rgba(0, 0, 0, 0.35);
	}
	
	.FilteredGr {
		border-radius: 6px 0 0 6px;
	}
	
	.FilteredEn {
		border-radius: 0 6px 6px 0;
	}
}