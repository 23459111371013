.Wrapper {
	min-width: 100%;
	background-color: #54545E;
	padding: 110px 50px;
	/*border: 2px dashed #ec9a05;*/
	font-family: Montserrat;
	font-size: 13px;
	font-weight: 400;
	line-height: 20px;
}

.Container {
	max-width: 1220px;
	/*border:4px dashed #7eaeff;*/
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
}

.Wrapper h3 {
	color: #FFFFFF;
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 600;
	line-height: 25px;
}

.Column1 {
	/*max-width: 320px;*/
	margin-right: 75px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.Column1 > p {
	max-width: 178px;
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	text-align: left;
}

.Column2 {
	/*calc(20px + 1 * 100vw / 1280);*/
	margin-right: 60px;
}

.Column3 {
	margin-right: 40px;
}

.Logo {
	height: 45px;
	width: 45px;
	margin-right: 7px;
}

.Column1 + .Column2 > h3, .Column3 > h3, .Column4 > h3 {
	margin-bottom: 15px;
}

.Column4 {
	margin-right: 100px;
}

.List > li + li {
	margin-top: 10px;
}

.List > li, .Column1 > p {
	color: #A1A1AB;
}

.WrapperLogo {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 35px;
}

.WrapperLogo p {
	font-family: Montserrat;
	font-size: 25px;
	font-weight: 600;
	line-height: 25px;
	letter-spacing: -0.02em;
	color: #FFFFFF;
}
.Contact{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}
.Contact > span{
	color: rgba(255, 255, 255, 1);
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
	text-align: right;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.Contact > p{
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
	text-align: left;
	color: #d8d8d8;
}
.TR{
	margin-top: 5px;
}
.Tel{
	margin-right: 7px;
}
.Mail{
	margin-right: 7px;
	width: 22px;
	height: 30px;
}
@media only screen and (max-width: 1050px) {
	.Container {
		max-width: 1220px;
		/*border:4px dashed #7eaeff;*/
		margin: 0 auto;
		row-gap: 40px;
		column-gap: 40px;
		justify-content: flex-start;
	}
	
	.Column1, .Column2, .Column3, .Column4 {
		margin: 0 0;
	}
}

@media only screen and (max-width: 800px) {
	.Wrapper {
		border: 2px dashed darkslategrey;
		padding: calc(45px + 50 * 100vw / 800) calc(25px + 22 * 100vw / 800);
	}
	
}

@media only screen and (max-width: 420px) {
	.Container{
		flex-direction: column;
	}
	.Column1 > p {
		max-width: 320px;
	}
}