.itemBtn{
	width: 100%;
	padding: 13px 0 13px 15px;
	cursor: pointer;
	display: flex;
	align-items: center;
	margin: 0;
	background: none;
	border: none;
	font-family: InterMedium;
	font-size: 17px;
	font-weight: 500;
	line-height: 25px;
	color:#FFFFFF;
	border-bottom: 1px solid #ccc;
}
.LinkActive {
	width: 100%;
	padding: 13px 0 13px 15px;
	cursor: pointer;
	display: flex;
	align-items: center;
	margin: 0;
	background: none;
	border: none;
	font-family: InterMedium;
	font-size: 17px;
	font-weight: 500;
	line-height: 25px;
	color: rgb(232, 232, 232);
}
.itemBtn:hover{
	background-color: rgba(217, 231, 244, 0.05);
}
.app {
	width: 100%;
	font-family: sans-serif;
	margin-top: 1rem;
	border-top: 1px solid #ccc;
}
.Szh{
	transition:  all 0.5s ;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 480px;
	/*border:2px dashed purple;*/
}

.item {
	/*border-bottom: 1px solid rgba(161, 161, 171, 1);*/
	/*border-top: 1px solid rgba(161, 161, 171, 1);*/
	/*padding: 30px 0 ;*/
	width: 100%;
}
.WrapperHeaderAccordion{
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.HeaderP{
	font-family: Montserrat;
	font-size: 13px;
	font-weight: 400;
	line-height: 20px;
	color: #54545E;
	padding-top: 10px;
}
.Wr{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.itemBtnExpanded {
	border-bottom: 1px solid rgba(161, 161, 171, 1);
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	
}

.itemContent {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	transition: height 0.4s ease-in-out;
}

.itemPanel {
	width: 100%;
	font-family: Montserrat;
	font-size: 13px;
	font-weight: 400;
	line-height: 20px;
	color: #54545E;
}

.chevron {
	margin-right: 15px;
	margin-left: auto;
	transition:  0.4s ease-in-out;
}

.itemBtnExpanded .chevron {
	transform: rotate(180deg);
}