.Wrapper {
	min-width: 100%;
	/*background-color: #FFFFFF;*/
	padding: 110px 50px;
	/*border: 2px dashed #ec9a05;*/
	background-image: url("imgs/bg.png");
}

.Container {
	height: 100%;
	max-width: 1220px;
	/*border: 4px dashed #7eaeff;*/
	margin: 0 auto;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.WrapperBtn {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 30px;
}
.Description{
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
}
.Column1{
	display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;
	max-width: 280px;
	margin-right: 50px;
}
.Column2{
	width: 100%;
	/*border:2px solid deeppink;*/
	display: flex;flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	column-gap: 40px;
	row-gap: 20px;
	flex-wrap: wrap;
}
.Item{
	position: relative;z-index: 1;
	max-width: 260px;
	transition: all 0.3s ease-out;
	height: 250px;
	display: flex;flex-direction: column;align-items: center;justify-content: space-between;
}
.Item > div:first-child{
	display: flex;flex-direction: column;align-items: center;justify-content: space-between;
	
}
.Item:hover::after{
	user-select: all;
	/*transition: all 0.9s;*/
	position:absolute;
	content:url("imgs/hover.png");
	z-index: 999;
	width:100%;
}
.Title{
	font-family: MontserratMedium;
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	letter-spacing: -0.02em;
	text-align: center;
	margin:10px 0;
}
.Item > span{
	margin-bottom: 15px;
}
.Desc{
	max-width: 243px;
	text-align: center;
	font-family: Montserrat;
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	margin-bottom: 10px;
}
.ColumnsWrapper{
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
}
@media only screen and (max-width: 1280px) {
	.Column1{
		margin-right: calc(10px + 40 * 100vw / 1380);
	}
}
@media only screen and (max-width: 1220px) {
	.ColumnsWrapper{
		flex-direction: column;
	}
	.Column1{
		max-width: 100%;
		margin-right: 0;
		margin-bottom: 40px;
	}
	.Column2{
		column-gap: 0;
		row-gap: 40px;
		justify-content: space-evenly;
	}
}
@media only screen and (max-width: 850px) {
	.Wrapper{
		padding: calc(45px + 50 * 100vw / 800) calc(25px + 22 * 100vw / 800);
	}
	.ColumnsWrapper{
		margin-top: 30px;
	}
	.Container{
		height: 100%;
		max-width: 1220px;
		margin:0 auto;
		display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;
	}
}
@media only screen and (max-width: 700px) {
	.ColumnsWrapper{
		margin-top: 0;
	}
}