.Wrapper {
	/*height: 554px;*/
	min-width: 100%;
	background-color: #FFFFFF;
	padding: 110px 50px;
}

.Container > button {
	color: #536C92;
}

.Arrow1 {
	color: #A1A1AB;
	opacity: 0.7;
	position: absolute;
	right: 60px;
	top: 0;
	cursor: pointer;
	/*z-index: 99999;*/
}

.Arrow2 {
	color: #A1A1AB;
	opacity: 0.7;
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
}

.WrapperHeaderAccordion {
	position: relative;
	/*border:2px dashed mediumpurple;*/
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
}

.Container {
	height: 100%;
	max-width: 1220px;
	/*border:4px dashed #7eaeff;*/
	margin: 0 auto;
	/*padding: 110px 0;*/
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
}

.WrapperHeaderAccordion > div {
	margin-left: 29px;
}

.Column > div {
	max-width: 935px;
}

.Column {
	/*border: 3px dashed #0a377e;*/
	position: relative;
	color: #54545E;
	max-height: 414px;
	width: 100%;
	max-width: 935px;
}

.Image {
	height: 414px;
	width: 283px;
	object-fit: cover;
	object-position: center;
}

.WrapperHeaderAccordion > div > h3 {
	font-family: MontserratSemiBold;
	font-size: 25px;
	font-weight: 600;
	line-height: 25px;
	margin-bottom: 15px;
}

.WrapperHeaderAccordion > div > p {
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
	margin-bottom: 54px;
}

.Count {
	top: 10px;
	left: 34%;
	color: rgba(161, 161, 171, 1);
	position: absolute;
}

@media only screen and (max-width: 1270px) {
	.Column {
		margin-top: 35px;
	}
}

@media only screen and (max-width: 1000px) {
	.Count {
		left: 39%;
	}
}

@media only screen and (max-width: 900px) {
	.Count {
		left: 42%;
	}
	
	.Wrapper {
		padding: calc(45px + 50 * 100vw / 800) calc(25px + 22 * 100vw / 800);
	}
}

@media only screen and (max-width: 800px) {
	.Count {
		left: 46%;
	}
}

@media only screen and (max-width: 700px) {
	.Column {
		margin-top: 0;
	}
}

@media only screen and (max-width: 635px) {
	.Image {
		height: 210px;
		width: 100%;
		object-fit: cover;
		object-position: center;
	}
	
	.Column {
		max-height: 100%;
	}
	
	.WrapperHeaderAccordion {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
	
	.WrapperHeaderAccordion > div > p {
		margin-bottom: 20px;
	}
	
	.WrapperHeaderAccordion > div {
		margin-left: 0;
		margin-right: 10px;
	}
}

@media only screen and (max-width: 635px) {
	.WrapperHeaderAccordion > div > p {
		margin-bottom: 20px;
	}
	
	.Column {
		padding-top: 40px;
	}
	
	.Arrow1 {
		right: 60px;
		top: -40px;
	}
	
	.Arrow2 {
		right: 0;
		top: -40px;
	}
	
	.Count {
		position: relative;
		left: 90%;
		/*right:10px;*/
	}
	
	.WrapperHeaderAccordion > div > h3 {
		margin-top: 30px;
	}
}
