.Wrapper{
	height: 90px;
	width: 100%;
	display: none;
	background-color: rgba(46, 46, 52, 0.7);
	backdrop-filter: blur(10px);
	padding: 19px 35px 19px 35px;
	position: fixed;
	top:0;
	z-index: 9999999999;
}
.Container{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.Menu{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.Menu > p{
	color:#FFFFFF;
	font-family: MontserratMedium;
	font-size: 13px;
	font-weight: 500;
	line-height: 15px;
	letter-spacing: -0.02em;
	margin-bottom: 10px;
}

.WrapperLogo {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.WrapperLogo p {
	font-family: Montserrat;
	font-size: 17px;
	font-weight: 600;
	line-height: 17px;
	letter-spacing: -0.02em;
	color:#FFFFFF;
}
.Logo{
	margin-right: 4px;
}
@media only screen and (max-width: 800px) {
	.Wrapper{
		display: block;
	}
}