.CustomSlider {
	/*display: flex;*/
	/*flex-direction: column;*/
	/*align-items: center;*/
	position: relative;
	height: 430px;
	/*border: 2px dashed greenyellow;*/
}

.Arrow1 {
	position: absolute;
	bottom: 6px;
	right: 8px;
	transform: rotate(180deg);
}

.Arrow2 {
	
	position: absolute;
	bottom: 6px;
	left: 8px;
}

.MyMainCustomDots {
	position: absolute;
	/*right: 40%;*/
	bottom: 10px;
	display: flex !important;
	flex-direction: row;
	left: 50%;
	transform: translate(-50%, 0)
}

.MyMainCustomDots > li {
	margin-right: 10px;
}

.MyMainCustomDots > li + li {
	list-style-type: none;
}

.MyMainCustomDots li button {
	height: 3px;
	width: 15px;
	border-radius: 5px;
	font-size: 0;
	padding: 0;
	border: none;
	/*display: inline-block;*/
	background-color: #A1A1AB;
	cursor: pointer;
}

.MyMainCustomDots :global(li.slick-active) button {
	height: 5px;
	width: 30px;
	border-radius: 5px;
	border: 2px solid #8DA1BE;
	transition: all .4s ease-in;
	background: none;
}

.MyMainCustomDots > li button:hover {
	height: 5px;
	width: 30px;
	border-radius: 5px;
	border: 1px solid #8DA1BE;
	background:none;
	transition: all .4s ease-in;
}

.Abra {
	max-width: 387px;
}

.Statistics {
	position: relative;
	z-index: 99;
	height: 390px;
	background-image: url("../imgs/glass.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	border-radius: 15px;
	display: flex!important;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 50px 40px 50px 42px;
}

.Statistics > p:first-child {
	font-family: Montserrat;
	font-size: 20px;
	font-weight: 400;
	line-height: 25px;
	color: #ECECEE;
}

.Statistics > h3 {
	font-family: Montserrat;
	font-size: 24px;
	font-weight: 600;
	line-height: 30px;
	color: #FFFFFF;
	margin: 25px 0 15px 0;
}

.Link:last-child {
	font-family: MontserratSemiBold;
	font-size: 20px;
	font-weight: 600;
	line-height: 25px;
	color: #FFFFFF;
	display: inline-block;
	position: relative;
	z-index: 1;
}

.Link:after {
	position: absolute;
	content: "";
	z-index: 999;
	bottom: 2px;
	left: 0;
	width: 100%;
	border-bottom: 1px solid #FFFFFF;
}
@media only screen and (max-width: 470px) {
	.Abra {
		max-width: 327px;
	}
	.Statistics {
		padding: 30px 20px 30px 20px;
		height: 327px;
	}
	.CustomSlider {
		position: relative;
		height: 370px;
	}
}
@media only screen and (max-width: 400px) {
	.Abra {
		max-width: 282px;
	}
	.Statistics > h3 {
		font-size: 22px;
	}
	.Link:last-child {
		font-size: 17px;
		font-weight: 600;
	}
}