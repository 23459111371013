.Wrapper{
	/*height: 554px;*/
	min-width: 100%;
	padding: 110px 50px;
	background-image: url("bg.png");
	background-size: 100% 100%;
	/*border: 2px dashed #ec9a05;*/
}
.Container{
	height: 100%;
	max-width: 1220px;
	/*border:4px dashed #7eaeff;*/
	margin:0 auto;
	/*padding: 110px 0;*/
	display: flex;flex-direction: row;justify-content: flex-start;align-items: flex-start;
	/*flex-wrap: wrap;*/
}
.Wrapper button{
	color:#536C92;
}

.FormBlock {
	display: flex;flex-direction: column;justify-content: center;align-items: flex-start;
	height: 334px;
	width: 100%;
	max-width: 839px;
	background-color: #D9E7F4;
	padding: 39px 31px 39px 39px;
}
.FormBlock .Label{
	text-transform: uppercase;
	font-family: MontserratSemiBold;
	font-size: 15px;
	font-weight: 400;
	line-height: 25px;
	color: #54545E;
}
.FormContainer{
	width: 100%;
	display: flex;justify-content: space-between;align-items: center;
	row-gap: 25px;
}
.Subscribe2{
	font-family: MontserratMedium;
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	letter-spacing: -0.02em;
	background: none;
	position: relative;
	z-index: 1;
	margin-right: 10px;
}
.Subscribe2::after{
	position:absolute;
	content:"";
	z-index: 999;
	bottom:2px;
	left:0;
	width:100%;
	border-bottom:1px solid #536C92;
}
.FormBlock > div:nth-child(3){
	display: flex;justify-content: flex-start;align-items: center;
	/*border: 2px solid palegreen;*/
	margin-top:19px;
}
.PolicyPrivacy > .PrivacyLAbel{
	/*border:2px dashed greenyellow;*/
	display: flex;justify-content: flex-start;align-items: center;
}
.PrivacyLAbel > p{
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
	color: #54545E;
	margin-left: 5px;
}
.FF{
	width: 100%;
}
.Column1{
	width: 46%;

	/*border:2px dashed greenyellow;*/
	margin-bottom: 12px;
}
.Column1 > .Field{
	margin: 15px 0;
}

.Column1 > h4 ~ h4{
	margin-top: 30px;
}
.Column1 > input{
	width: 100%;
	background: none;
	border:none;
	outline: none;
	border-bottom: 0.5px solid #54545E;
	padding-bottom: 5px;
	padding-left: 8px;
	font-family: Montserrat;
	font-size: 12px;
	line-height: 20px;
	text-align: left;
	color: #8e8e94;
}
.Btn{
	font-family: MontserratMedium;
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	letter-spacing: -0.02em;
	background: none;
	position: relative;
	z-index: 1;
	margin-right: 10px;
	color: #536C92;
}
.Btn::after{
	position:absolute;
	content:"";
	z-index: 999;
	bottom:2px;
	left:0;
	width:100%;
	border-bottom:1px solid #536C92;
}
.BtnWrapper{
	
	margin-top: 20px;
	display: flex;align-items: center;
}
.Success{
	height: 100%;
	margin: 0 auto;
	display: flex;flex-direction: column;align-items: center;
	justify-content: center;
}
.Success >p{
	text-align: center;
	margin-top: 25px;
}
@media only screen and (max-width: 800px) {
	.Wrapper{
		padding: calc(45px + 50 * 100vw / 800) calc(25px + 22 * 100vw / 800);
	}
}
@media only screen and (max-width: 700px) {
	.Container{
		height: 100%;
		max-width: 1220px;
		margin:0 auto;
		display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start;
	}
}
@media only screen and (max-width: 700px) {
	.FormBlock{
		min-height: 254px;
		padding: 20px 31px 15px 20px;
	}
	.Column1{
		width: 100%;
	}
}

